
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "ModalActions",
  emits: ["removeModal", "submit"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    pending: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      date: 0
    }
  },
  computed: {
    isAdd() {
      return !this.pending && this.data.content.action === 'add';
    },
    isEdit() {
      return !this.pending && this.data.content.action === 'edit';
    },
    isRemove() {
      return !this.pending && this.data.content.action === 'remove';
    },
    isMessage() {
      return !this.pending && this.data.content.action === 'message';
    },
    isInfo() {
      return !this.pending && this.data.content.action === 'info';
    },
    isInfoDisabled() {
      return this.pending && this.data.content.action === 'info';
    }
  },
  methods: {
    submit() {
      if (Date.now() - this.date < 1000) return;
      this.date = Date.now();
      this.$emit('submit');
    }
  }
});
