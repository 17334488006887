import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1614f68e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  src: "/images/common/modal/upload.svg",
  loading: "lazy",
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.content.title), 1),
    _createElementVNode("label", null, [
      _createElementVNode("input", {
        type: "file",
        name: "avatar",
        ref: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
      }, null, 544),
      (_ctx.data.content.method !== 'delete' && _ctx.data.content.upload_request)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["upload flex justify-center items-center", { uu: _ctx.preview_avatar || _ctx.data.inputs.data.avatar }])
          }, [
            (_ctx.preview_avatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "avatar",
                  src: _ctx.preview_avatar,
                  alt: ""
                }, null, 8, _hoisted_3))
              : (!_ctx.preview_avatar && _ctx.data.inputs.data.avatar)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "avatar",
                    src: _ctx.data.inputs.data.avatar,
                    alt: ""
                  }, null, 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("img", _hoisted_5))
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}