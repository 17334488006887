
import { defineComponent } from "vue-demi";
import { mask } from "vue-the-mask";
import { dynamicsObject } from "@/interfaces";
import { ERROR_UPLOAD_FILE, ERROR_UPLOAD_FILE_TYPE_XLSX } from "@/utils/constants";

export default defineComponent({
  name: "Input",
  emits: ["selectDropElement", "toggleDrop", "selectBoxElement", "inputKeyUp", "inputFocus"],
  directives: {
    mask
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      exampleClick: false
    }
  },
  methods: {
    selectFile() {
      const file = (this.$refs.excel as any).files[0];
      if (!file)
        return this.$store.commit("createNotification", {
          status: "error",
          message: ERROR_UPLOAD_FILE,
        });
      if (!file.type.includes("openxml")) {
        return this.$store.commit("createNotification", {
          status: "error",
          message: ERROR_UPLOAD_FILE_TYPE_XLSX,
        });
      }
      this.data.excel = file;
    },
    showInstrumentName(el: dynamicsObject) {
      if (el.sign) return `${el.name} - ${el.sign}`;
      if (el.title) return `${el.name} (${el.title})`;
      
      return el.name || el.symbol;
    },
    inputKeyUp(event: any) {
      this.$emit('inputKeyUp', { event, input: this.input });
    }
  }
});
