import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42fd4944"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-end" }
const _hoisted_2 = {
  key: 7,
  class: "btn btn--green btn--disabled"
}
const _hoisted_3 = {
  key: 8,
  class: "btn btn--green btn--disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data.content.action === 'message')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn--white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeModal')))
        }, "Нет"))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "btn btn--white",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('removeModal')))
        }, "Отмена")),
    (_ctx.isAdd)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "btn btn--green",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
        }, "Добавить"))
      : (_ctx.isEdit)
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "btn btn--green",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
          }, _toDisplayString(_ctx.data.content.button || 'Сохранить'), 1))
        : (_ctx.isRemove)
          ? (_openBlock(), _createElementBlock("button", {
              key: 4,
              class: "btn btn--green",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
            }, "Удалить"))
          : (_ctx.isMessage)
            ? (_openBlock(), _createElementBlock("button", {
                key: 5,
                class: "btn btn--green",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, "Да"))
            : (_ctx.isInfo)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 6,
                  class: "btn btn--green",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
                }, "Ознакомлен(а)"))
              : (_ctx.isInfoDisabled)
                ? (_openBlock(), _createElementBlock("button", _hoisted_2, "Ознакомлен(а)"))
                : (_openBlock(), _createElementBlock("button", _hoisted_3, "Обработка..."))
  ]))
}