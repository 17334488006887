export default ({ id }: any) => {
  const contents = [
    // FAQ
    {
      title: 'Добавить FAQ',
      action: 'add',
      id: 'faq-add',
      request: '/api/faq',
      method: 'post',
      emit: 'createFAQ',
    },
    {
      title: 'Редактировать FAQ',
      action: 'edit',
      id: 'faq-edit',
      request: '/api/faq',
      method: 'patch',
      emit: 'editFAQ',
    },
    {
      title: 'Удалить FAQ',
      action: 'remove',
      id: 'faq-remove',
      request: '/api/faq',
      message: 'этот вопрос/ответ',
      method: 'delete',
      emit: 'removeFAQ',
    },
    // Assets
    {
      title: 'Добавить смешанный актив',
      action: 'add',
      id: 'asset-add',
      request: '/api/asset',
      method: 'post',
      emit: 'create',
    },
    {
      title: 'Редактировать валюту',
      action: 'edit',
      id: 'asset-edit',
      request: '/api/asset',
      method: 'patch',
      emit: 'edit',
    },
    {
      title: 'Удалить смешанный актив',
      action: 'remove',
      id: 'asset-remove',
      request: '/api/asset',
      message: 'этот актив',
      method: 'delete',
      emit: 'remove',
    },
    // Currency
    {
      title: 'Добавить валюту',
      action: 'add',
      id: 'currency-add',
      request: '/api/currency',
      method: 'post',
      emit: 'create',
    },
    {
      title: 'Редактировать валюту',
      action: 'edit',
      id: 'currency-edit',
      request: '/api/currency',
      method: 'patch',
      emit: 'edit',
    },
    {
      title: 'Удалить валюту',
      action: 'remove',
      id: 'currency-remove',
      request: '/api/currency',
      message: 'эту валюту',
      method: 'delete',
      emit: 'remove',
    },
    // Expert
    {
      title: 'Выберите курс',
      action: 'add',
      id: 'questionaire-select-course',
      request: '/api/course',
      method: 'patch',
      emit: 'selectQuestionnaireCourse',
    },
    {
      title: 'Добавить эксперта',
      action: 'add',
      id: 'expert-add',
      request: '/api/user/expert',
      upload_request: '/api/user/avatar',
      method: 'post',
      emit: 'create',
    },
    {
      title: 'Редактировать эксперта',
      action: 'edit',
      id: 'expert-edit',
      request: '/api/user/expert',
      upload_request: '/api/user/avatar',
      method: 'patch',
      emit: 'edit',
    },
    {
      title: 'Удалить эксперта',
      action: 'edit',
      id: 'expert-remove',
      request: '/api/user/expert',
      upload_request: '',
      method: 'delete',
      emit: 'remove',
      message: 'этого эксперта',
      button: 'Удалить'
    },
    // Support
    {
      title: 'Добавить аккаунт СП',
      action: 'add',
      id: 'support-add',
      request: '/api/user/support',
      upload_request: '/api/user/avatar',
      method: 'post',
      emit: 'create',
    },
    {
      title: 'Редактировать аккаунт СП',
      action: 'edit',
      id: 'support-edit',
      request: '/api/user/support',
      upload_request: '/api/user/avatar',
      method: 'patch',
      emit: 'edit',
    },
    {
      title: 'Удалить аккаунт СП',
      action: 'remove',
      id: 'support-remove',
      request: '/api/user',
      upload_request: '',
      method: 'delete',
      emit: 'remove',
      message: 'этот аккаунт СП',
    },
    // Process questionnaire
    {
      title: 'Удалить отчёт',
      action: 'remove',
      id: 'questionnaire_process-remove',
      request: `/api/questionnaire`,
      upload_request: '',
      method: 'delete',
      emit: 'remove',
      message: 'этот отчёт',
    },
    // Ready questionnaire
    {
      title: 'Удалить отчёт',
      action: 'remove',
      id: 'questionnaire_ready-remove',
      request: `/api/questionnaire`,
      upload_request: '',
      method: 'delete',
      emit: 'remove',
      message: 'этот отчёт',
    },
    {
      title: 'Удалить отчёт',
      action: 'remove',
      id: 'questionnaires-remove',
      request: `/api/questionnaire`,
      upload_request: '',
      method: 'delete',
      emit: 'remove',
      message: 'этот отчёт',
    },
    {
      title: 'Удалить архивный отчёт',
      action: 'remove',
      id: 'questionnaires_archive-remove',
      request: `/api/questionnaire`,
      upload_request: '',
      method: 'delete',
      emit: 'remove',
      message: 'этот отчёт',
    },
    {
      title: 'Удалить отчёт',
      action: 'remove',
      id: 'questionnaire_student-remove',
      request: `/api/questionnaire`,
      upload_request: '',
      method: 'delete',
      emit: 'remove',
      message: 'этот отчёт',
    },
    // Instruments quesionnaire
    {
      title: 'Отправить анкету студенту',
      action: 'edit',
      id: 'questionnaire-send',
      emit: `sendQuestionnaire`,
    },
    {
      title: 'Добавить инструмент',
      action: 'add',
      id: 'questionnaire_instrument-create',
      emit: `createQuestionnaire`,
    },
    {
      title: 'Редактировать инструмент',
      action: 'edit',
      id: 'questionnaire_instrument-edit',
      emit: `editQuestionnaire`,
    },
    {
      title: 'Добавить инструмент',
      action: 'add',
      id: 'common_instrument-create',
      request: `/api/investment`,
      method: 'post',
      emit: `commonInstrumentCreate`,
    },
    {
      title: 'Редактировать инструмент',
      action: 'edit',
      id: 'common_instrument-edit',
      request: `/api/investment`,
      method: 'patch',
      emit: `commonInstrumentEdit`,
    },
    {
      title: 'Удалить инструмент',
      action: 'remove',
      id: 'common_instrument-remove',
      emit: `commonInstrumentRemove`,
      request: `/api/investment`,
      method: 'delete',
      message: 'этот инструмент',
    },
    {
      title: 'Редактировать комментарий эксперта',
      action: 'edit',
      id: 'instrument-comment',
      emit: `commentQuestionnaire`,
    },
    {
      title: 'Удалить инструмент',
      action: 'remove',
      id: 'questionnaire_instrument-remove',
      emit: `removeQuestionnaire`,
      message: 'этот инструмент',
    },
    // students
    {
      title: 'Изменить эксперта',
      action: 'edit',
      request: '/api/student/expert',
      id: 'students-expert',
      method: 'patch',
      emit: 'edit',
    },
    {
      title: 'Сменить эксперта',
      action: 'edit',
      request: '/api/student/expert/change',
      id: 'students-expert-change',
      method: 'patch',
      emit: 'editExpertStudents',
    },
    {
      title: 'Переместить работы',
      action: 'edit',
      request: '/api/student/expert/change/list',
      formdata: true,
      id: 'students-expert-change-list',
      method: 'patch',
      emit: 'editExpertListStudents',
    },
    {
      title: 'Добавить поток курсу',
      action: 'add',
      request: '/api/course/stream',
      id: 'stream-course-add',
      method: 'post',
      emit: 'addStreamDate',
    },
    {
      title: 'Загрузить студентов',
      action: 'edit',
      request: '/api/excel/students',
      formdata: true,
      id: 'students-added-list',
      method: 'patch',
      emit: 'students',
    },
    {
      title: 'Удалить студентов',
      action: 'edit',
      request: '/api/student/delete/list',
      formdata: true,
      id: 'students-delete-list',
      method: 'patch',
      emit: 'deleteStudentsList',
    },
    {
      title: 'Редактировать студента',
      action: 'edit',
      request: '/api/student',
      id: 'students-edit',
      method: 'patch',
      emit: 'edit',
    },
    {
      title: 'Изменить дату потока',
      action: 'edit',
      request: '/api/student/stream',
      id: 'students-stream',
      method: 'patch',
      emit: 'edit',
    },
    {
      title: 'Удалить файл студента',
      action: 'remove',
      request: '/api/student/file',
      id: 'students-file',
      method: 'delete',
      emit: 'edit',
      message: 'файл студента',
    },
    {
      title: 'Удалить студента',
      action: 'remove',
      request: '/api/student',
      id: 'students-remove',
      method: 'delete',
      emit: 'remove',
      message: 'этого студента и его работу',
    },
    {
      title: 'Изменить пароль',
      action: 'edit',
      request: '/api/student/password',
      id: 'students-password',
      method: 'patch',
    },
    {
      title: 'Добавить студента',
      action: 'add',
      request: '/api/student',
      id: 'students-add',
      method: 'post',
      emit: 'create',
    },
    // insurance
    {
      title: 'Изменить страховой продукт',
      action: 'edit',
      id: 'insurance-edit',
      emit: 'editInsurance',
    },
    // verification
    {
      title: 'Отправить на проверку',
      action: 'message',
      id: 'verification',
      emit: 'verification',
      message: 'Вы действительно хотите отправить работу на проверку эксперту?'
    },
    {
      title: 'Информация',
      id: 'questionnaire-create-info',
      action: 'info',
      emit: 'questionnaireCreate',
    }
  ];
  return contents.find(content => content.id === id);
};