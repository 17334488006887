
import { defineComponent } from "vue-demi";

export default defineComponent({
  emits: ['changePending'],
  name: "ModalContainerInfo",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkboxes: [false, false, false, false, false],
    };
  },
  methods: {
    onChange() {
      if (this.checkboxes.filter((c) => !c).length === 0) {
        this.$emit('changePending', false);
      } else {
        this.$emit('changePending', true);
      }
    },
  },
});
