import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-231b7027"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Inputs = _resolveComponent("Inputs")!

  return (_openBlock(), _createElementBlock("main", null, [
    (_ctx.isMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.data.content.message), 1))
      : _createCommentVNode("", true),
    (_ctx.data.content.action !== 'remove')
      ? (_openBlock(), _createBlock(_component_Inputs, {
          key: 1,
          action: _ctx.data.content.action,
          datas: _ctx.datas,
          module: _ctx.data.inputs,
          targetTotal: _ctx.targetTotal,
          targetCurrencyId: _ctx.targetCurrencyId,
          targetId: _ctx.targetId,
          course: _ctx.$store.getters.course
        }, null, 8, ["action", "datas", "module", "targetTotal", "targetCurrencyId", "targetId", "course"]))
      : (_ctx.isRemove)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.messageRemove), 1))
        : _createCommentVNode("", true)
  ]))
}