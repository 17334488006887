
import { ERROR_UPLOAD_FILE, ERROR_UPLOAD_FILE_TYPE_IMAGE } from "@/utils/constants";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "ModalHeader",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      preview_avatar: null,
    };
  },
  methods: {
    uploadFile() {
      const file = (this.$refs.file as any).files[0];
      this.data.inputs.data.upload = file;
      if (!file)
        return this.$store.commit("createNotification", {
          status: "error",
          message: ERROR_UPLOAD_FILE,
        });
      if (
        !(
          file.type.includes("jpeg") ||
          file.type.includes("png") ||
          file.type.includes("webp")
        )
      ) {
        return this.$store.commit("createNotification", {
          status: "error",
          message: ERROR_UPLOAD_FILE_TYPE_IMAGE,
        });
      }
      const img = new Image();
      img.onload = () => {
        let fr = new FileReader();
        fr.onload = (() => {
          return (e: any) => {
            this.preview_avatar = e.target.result;
          };
        })();
        fr.readAsDataURL(file);
      };
      img.src = URL.createObjectURL(file);
    },
  },
});
