
import { defineComponent } from "vue-demi";
import Inputs from "./ModalInputs.vue";

export default defineComponent({
  name: "ModalContainer",
  props: {
    data: {
      type: Object,
      required: true,
    },
    datas: {
      type: Object,
      required: true,
    },
    targetTotal: {},
    targetCurrencyId: {
      type: String,
      default: 'RUB',
    },
    targetId: {
      type: [Number, String]
    }
  },
  computed: {
    isMessage() {
      return this.data.content.action === 'message';
    },
    isRemove() {
      return this.data.content.action === 'remove';
    },
    messageRemove() {
      return `Вы действительно хотите удалить ${ this.data.content.message }?`;
    }
  },
  components: {
    Inputs,
  },
});
